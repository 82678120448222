import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Notice from "../../components/notice"
import Navbar from "../../components/navbar"
import CallToAction from "../../components/callToAction"
import Footer from "../../components/footer"
import ResourceFeatured from "../../components/resourceFeatured"
import ResourceFavorite from "../../components/resourceFavorite"
import Form from "../../components/hubspotForm/"
import CustomersSection from "../../components/customersSection"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
// import ResourceForm from "../../components/resourceForm"
import RequestForm from "../../components/request-form"
import { CookieProvider } from "../../contexts/cookieProvider"
import Seo from "../../components/seo"
import utmContext from "../../contexts/utmProvider"
import _ from "lodash"

const Resource = ({ data, pageContext, location }) => {
  const NoticeData = data?.notice?.edges?.[0]?.node
  const NavbarData = data?.navbar?.edges?.[1]?.node
  const CalltoActionData = data?.callToAction?.edges?.[0]?.node
  const footerData = data?.footerColumn?.edges?.[0]?.node

  const featured = data.featured.edges[0]?.node

  const customerSectionItem = data.customlogo.nodes[0]

  const metaDescription = featured?.synopsis
  const url = typeof window !== "undefined" ? window.location.href : ""

  const resourceData = data.allContentfulResource.edges[0].node
  const imageUrl = resourceData?.resourceGraphic?.file?.url || featured?.resourceGraphic?.file?.url
  const eventDatetime = resourceData.eventDatetime
  const richText = resourceData.description
  const resourceTag = resourceData.resourceTag
  const title = resourceData.title
  const synopsis = resourceData.synopsis
  const favoriteResourceArticles =
    resourceData?.resource_relation?.relatedResources
  const hubspotData = {}
  const hubspot = pageContext.hubspot.data.allHubspotDataCustom.nodes[0]

  if (hubspot) {
    Object.assign(hubspotData, hubspot)
  }
  const resourceParamSearch = location.search.replaceAll("?", "")
  const utmInfoArray = resourceParamSearch.split("&")
  const { utm, addUtm } = React.useContext(utmContext)
  useEffect(() => {
    const utmKeyArrays = [
      "utm_content",
      "utm_campaign",
      "utm_medium",
      "utm_source",
      "utm_term",
    ]
    let utmData = {}
    if (utmInfoArray) {
      utmInfoArray.forEach(item => {
        const utmInfoKey = item.split("=")[0]
        if (utmKeyArrays.includes(utmInfoKey)) {
          utmData[utmInfoKey] = item.split("=")[1]
        }
      })
      if (!_.isEqual(utm, utmData)) {
        addUtm(utmData)
      }
    }
  }, [utmInfoArray, utm])

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="mt-0 mb-2 text-5xl font-medium leading-tight text-blue-600 heading1 font-workSans">
          {children}
        </h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="mt-0 mb-2 text-4xl font-medium leading-tight text-blue-600 heading2 font-workSans">
          {children}
        </h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="mt-0 mb-2 text-3xl font-medium leading-tight text-blue-600 heading3 font-workSans">
          {children}
        </h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="mt-0 mb-2 text-2xl font-medium leading-tight text-blue-600 heading4 font-workSans">
          {children}
        </h4>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <h4 className="mt-0 mb-2 text-xl font-medium leading-tight text-blue-600 heading5 font-workSans">
          {children}
        </h4>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <h4 className="mt-0 mb-2 text-base font-medium leading-tight text-blue-600 heading6 font-workSans">
          {children}
        </h4>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="text-[19px] font-normal leading-6">{children}</p>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img
          src={`https:${node.data.target.fields.file["en-US"].url}`}
          alt=""
        />
      ),
      [BLOCKS.HEADING_5]: (node, children) => {
        return (
          <h5 className="text-black font-industryBold leading-lineheight140 max-w-[493px] mt-4 text-left text-base md:text-[21px] font-normal tracking-wide">
            {children}
          </h5>
        )
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return (
          <ul className="mt-8 list-disc ml-7">
            <li className="text-black font-workSans font-normal leading-6 mt-4 text-left text-[19px]">
              {children}
            </li>
          </ul>
        )
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <p className="md:text-[19px] font-normal font-workSans leading-6 text-black">
            {children}
          </p>
        )
      },
    },
    renderMark: {
      [MARKS.BOLD]: text => <b className="font-bold leading-6">{text}</b>,
    },
  }

  return (
    <>
      <CookieProvider>
        <Seo
          title={title}
          metaDescription={metaDescription}
          imageUrl={imageUrl}
          url={url}
        />
        {NoticeData && <Notice NoticeData={NoticeData} />}
        {NavbarData && <Navbar NavbarData={NavbarData} />}

        <Form
          FormData={{
            eventDatetime: eventDatetime,
            richText: richText,
            tagline: resourceTag,
            title: title,
            synopsis:synopsis,
            desc: "",
            formGraphic: resourceData.resourceGraphic,
          }}
          hubspotData={hubspotData}
          color="text-white"
        />
        			<CustomersSection  customersSectionData={customerSectionItem} />

        {favoriteResourceArticles && (
          <ResourceFavorite data={favoriteResourceArticles} />
        )}
        {CalltoActionData && <CallToAction CalltoActionData={CalltoActionData} />}
        <Footer
          variant="dark"
          footerData={footerData?.footerColumns}
          atlasOneLogo={footerData?.atlasOneLogo}
        />
      </CookieProvider>
    </>
  )
}
export default Resource

export const query = graphql`
  query resourcesTemplateQuery($slug: String!) {
    allContentfulResource(
      sort: { fields: [updatedAt], order: DESC }
      filter: { title: { eq: $slug } }
    ) {
      edges {
        node {
          title
          eventDatetime
          synopsis
          description {
            raw
          }
          featured
          resourceGraphic {
            title
            file {
              url
            }
          }
          resourceTag
          hubspotFormId
          
          featured_resource_articles {
            articlesresources {
              ... on ContentfulArticle {
                id
                articleTitle
                tags
                articleBannerImage {
                  file {
                    url
                  }
                }
              }
              ... on ContentfulResource {
                id
                title
                resourceTag
                resourceGraphic {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    featured: allContentfulResource(
      limit: 1
      sort: { fields: [updatedAt], order: DESC }
      filter: { featured: { eq: true } }
    ) {
      edges {
        node {
          title
          eventDatetime
          synopsis
          description {
            raw
          }
          featured
          resourceGraphic {
            title
            file {
              url
            }
          }
          resourceTag
          hubspotFormId
        }
      }
    }
    resourcetags: allContentfulResource {
      edges {
        node {
          resourceTag
          title
        }
      }
    }
    customlogo: allContentfulCustomersSection {
      nodes {
        customers {
          logo {
            description
            file {
              url
            }
            title
          }
          customerName
        }
        title
        elementType
      }
    }
    notice: allContentfulNotice {
      edges {
        node {
          elementType
          theme
          noticeTitle {
            raw
          }
          tagline
          actionUrl
          noticeActionText
        }
      }
    }
  
    navbar: allContentfulHeader {
      edges {
        node {
          atlasOneLogo {
            image {
              file {
                url
              }
              title
            }
          }
          headerButtons {
            ... on ContentfulPrimaryButton {
              elementType
              id
              theme
              title
              url
            }
            ... on ContentfulSecondaryButton {
              elementType
              id
              theme
              title
              url
            }
          }
          headerMenuItems {
            id
            title
            headerMenuDropdown {
              items {
                description
                icon {
                  file {
                    url
                  }
                  title
                }
                title
                url
                page {
                  slug
                  title
                }
              }
            }
          }
        }
      }
    }
  
    callToAction: allContentfulCallToAction {
      edges {
        node {
          elementType
          theme
          title
          desc:description
          buttons {
            ... on ContentfulSecondaryButton {
              elementType
              theme
              title
              url
            }
            ... on ContentfulPrimaryButton {
              elementType
              theme
              title
              url
            }
          }
        }
      }
    }
  
    footerColumn: allContentfulFooter {
      edges {
        node {
          elementType
          footerColumns {
            columnTitle
            links {
              title
              description
              icon {
                file {
                  url
                }
              }
              url
              page {
                slug
                title
              }
            }
          }
          atlasOneLogo {
            image {
              file {
                url
              }
              title
            }
          }
        }
      }
    }
    allContentfulPage {
      edges {
        node {
          slug
          title
          metaTitle
          metaDescription
          ogImage {
            file {
              url
            }
          }
          sections {
            ... on ContentfulNotice {
              elementType
              theme
              noticeTitle {
                raw
              }
              tagline
              actionUrl
              noticeActionText
            }

            ... on ContentfulHeader {
              headerMenuItems {
                title
                headerMenuDropdown {
                  items {
                    description
                    icon {
                      file {
                        url
                      }
                      title
                    }
                    title
                    url
                    page {
                      slug
                      title
                    }
                  }
                }
              }
              headerButtons {
                ... on ContentfulPrimaryButton {
                  elementType
                  id
                  theme
                  title
                  url
                }
                ... on ContentfulSecondaryButton {
                  elementType
                  id
                  theme
                  title
                  url
                }
              }
              atlasOneLogo {
                image {
                  file {
                    url
                  }
                  title
                }
              }
            }
            ... on ContentfulCallToAction {
              elementType
              theme
              title
              buttons {
                ... on ContentfulSecondaryButton {
                  elementType
                  theme
                  title
                  url
                }
                ... on ContentfulPrimaryButton {
                  elementType
                  theme
                  title
                  url
                }
              }
            }
            ... on ContentfulFooter {
              elementType
              ... on Node {
                ... on ContentfulFooterColumn {
                  columnTitle
                  links {
                    title
                    description
                    icon {
                      file {
                        url
                      }
                    }
                    url
                    page {
                      slug
                      title
                    }
                  }
                }
              }
              atlasOneLogo {
                image {
                  file {
                    url
                  }
                  title
                }
              }
              footerColumns {
                columnTitle
                links {
                  title
                  url
                }
              }
            }

          }
        }
      }
    }
  }
`