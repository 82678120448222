import React from "react"
import ResourceCard from "../../components/resourceCard"
import BlogCard from "../../components/blog-card"

const ResourceFavorite = ({ data }) => {
	return (
		<div className="w-screen bg-white md:min-h-[685px] px-5 md:px-10 xl1:px-40">
			<div className="max-w-screen-xl mx-auto">
				<div className="flex flex-wrap items-center justify-between py-10 md:py-20">
					<p className="Saira-Bold capitalize font-normal md:text-[44px] text-black leading-lineheight120">
						You might also like
					</p>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-[30px]">
					{data && data.map((item,index) => {
						if (Array.isArray(item.tags) && item?.tags.length > 0)
							return (
								<BlogCard
									title={item?.tags?.[0]}
									heading={item?.articleTitle}
									src={item?.articleBannerImage?.file?.url}
									link={item?.articleTitle.replace(/\s+/g, '-').toLowerCase()}
									key={`blogCard-${index}`}
								/>
							)
						if (item.resourceTag !== null && item.resourceTag !== "")
							return (
								<ResourceCard
									title={item?.resourceTag}
									heading={item?.title}
									src={item?.resourceGraphic?.file?.url}
									link={item?.title.replace(/\s+/g, '-').toLowerCase()}
									key={`resourceCard-${index}`}
								/>
							)
						else
								return null
					})}
				</div>
			</div>
		</div>
	)
}

export default ResourceFavorite 